<template>
  <div>
    <v-layout wrap justify-center py-lg-8>
      <v-flex xs12 py-4t text-center pt-8>
        <span
          class="bold"
          style="
            border-bottom: 5px solid #ff8533;
            font-size: 30px;
            padding-left: 20px;
            padding-right: 20px;
          "
        >
         Teaching Staffs</span
        >
      </v-flex>
      <v-flex xs12 lg10>
        <v-layout wrap pt-4 pt-lg-10 justify-center>
          <v-flex xs12 sm6 md3 v-for="(item,i) in staffData " :key="i" pa-4>
            <v-layout wrap>
              <v-flex xs12 >
                <v-img class="mx-auto"
                  height="250px"
                  width="250px"
                  :src="require('../assets/images/staff/'+item.image)"
                >
                </v-img>
              </v-flex>
              <v-flex xs12 text-center>
                <span class="bold">{{item.name}}</span>
              </v-flex>
              <v-flex xs12 text-center>
                <span class="regular">{{item.position}}</span>
              </v-flex>
                  <v-flex xs12 text-center>
                <span class="regular">{{item.qul}}</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
     <v-layout wrap justify-center py-lg-8>
      <v-flex xs12 py-4t text-center pt-8>
        <span
          class="bold"
          style="
            border-bottom: 5px solid #ff8533;
            font-size: 30px;
            padding-left: 20px;
            padding-right: 20px;
          "
        >
        Non-Teaching Staffs</span
        >
      </v-flex>
      <v-flex xs12 lg10>
        <v-layout wrap pt-4 pt-lg-10 justify-center>
          <v-flex xs12 sm6 md3 v-for="(item,i) in NonTeaching " :key="i" pa-4>
            <v-layout wrap>
              <v-flex xs12 >
                <v-img class="mx-auto"
                  height="250px"
                  width="250px"
                  :src="require('../assets/images/staff/'+item.image)"  
                >
                </v-img>
              </v-flex>
              <v-flex xs12 text-center>
                <span class="bold">{{item.name}}</span>
              </v-flex>
              <v-flex xs12 text-center>
                <span class="regular">{{item.position}}</span>
              </v-flex>
                  <v-flex xs12 text-center>
                <span class="regular">{{item.qul}}</span>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
    data(){
        return{

            staffData:[
               
               
               
                 {
                   image:"Rajesh.jpg" ,
                   name:"Rajesh PM",
                   position:"Teacher Educator",
                    qul:"(BA, B.Ed)"
                },
                 {
                   image:"Selvy.jpg" ,
                   name:"Selvy MM",
                   position:"Teacher Educator",
                     qul:"(MA, B.Ed, M.Ed, SET)"
                 },
                  {
                   image:"Anu.jpg" ,
                   name:"Anu Lawrence",
                   position:"Teacher Educator",
                       qul:"(M.Sc, B.Ed, SET)"
                },
            ],
            NonTeaching: [
                {
                   image:"christamma.jpg" ,
                   name:"Christamma H",
                   position:"Clerk",
                     qul:"(PDC)"
                },
                {
                   image:"Assistant.jpg" ,
                   name:"Vimala Joseph",
                   position:"Office Assistant",
                     qul:"(PDC)"
                },
                 {
                   image:"Lukose Antony.jpeg" ,
                   name:"Lukose Antony",
                   position:"PTM",
                     qul:"(Plus Two)"

                },
                
            ]


        }
    }


}
</script>